import React, { useState } from 'react'
import { useEffect } from 'react';
import closeBtn from '../assets/images/close-button-2.svg';

export default function() {

  const [visible, setVisible] = React.useState(false);
  useEffect(()=>{
    let pop_status = localStorage.getItem('pop_status');
    if(!pop_status){
      setVisible(true);
      localStorage.setItem('pop_status',1);
    }
  },[])

  if(!visible) return null;

    return (
      <div  className="tp-banner">
        <div className="tp-banner-container">
          <p className="tp-banner-text-1">By using our website, you acknowledge that you have read, and do hereby accept the terms and conditions contained in this.</p>
          <p className="tp-banner-text">Check out <a href="/terms/" target="_blank">Terms of Service</a> or  <a href="/privacy/" target="_blank">Privacy Policy</a></p>
            <a href="javascript:void(0);" className="tp-close-icon" onClick={() => setVisible(false)}><img src={closeBtn} alt="close button" /></a>
        </div>
      </div>
    )
}

