import React, { useState, useEffect } from 'react'
import { graphql, useStaticQuery } from "gatsby"
import '../assets/style.css';
import { Container, Row, Col, Spinner,  } from 'react-bootstrap';
import "react-circular-progressbar/dist/styles.css";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import {PortableText} from '@portabletext/react'

// const renderTime = ({remainingTime}) => {
//   return (
//     <div className="timer">
//         <div className="text"><img style={{width:"68px", height:"68px"}} src={require('../assets/images/testimonials/1.png')}/></div>
//     </div>
//   );
// };
// import ProgressProvider from "../ProgressProvider"; 



export default function() {
    const data = useStaticQuery(query);
    const {allSanityTestimonials:{
        nodes:posts
    }} = data

    // const posts = [
    //   { username: 'Ahmed Musa', position: 'Head of CRO', company:"Ceil Worldwide", description:`" First TEST Working with Sogody has been a pleasure for many, many years. Since my time at DBi, I enjoyed the ride with them until now that we are personalizing many experiences for Samsung. There were times we even visited Lorik, Tomor and their team in Prishtina, welcomed by an exceptional hosting"` , img:"1.png"},
    //   { username: 'Mark Pybus', position: 'CEO', company:"Creative CX", description:`"Second test Working with Sogody has been a pleasure for many, many years. Since my time at DBi, I enjoyed the ride with them until now that we are personalizing many experiences for Samsung. There were times we even visited Lorik, Tomor and their team in Prishtina, welcomed by an exceptional hosting"`, img:"2.png"},
    //   { username: 'Waqar Riaz', position: 'Digital Hub Lead', company:"Unilever", description:`"Third Test Working with Sogody has been a pleasure for many, many years. Since my time at DBi, I enjoyed the ride with them until now that we are personalizing many experiences for Samsung. There were times we even visited Lorik, Tomor and their team in Prishtina, welcomed by an exceptional hosting"`, img:"3.png"},
    //   { username: 'Laura Robinson', position: 'Head of CRO', company:"Brainlabs Ltd.", description:`"Fourth test Working with Sogody has been a pleasure for many, many years. Since my time at DBi, I enjoyed the ride with them until now that we are personalizing many experiences for Samsung. There were times we even visited Lorik, Tomor and their team in Prishtina, welcomed by an exceptional hosting"`, img:"4.png"},
    // ];

        const [activeIdx, setActiveIdx] = useState(0);
          const [className, setClassName] = useState("fade-in");
        // const [counterKey, setCounterKey] = useState(0);

    // useEffect(() => {
    //   console.log('called tybe');
    //   setInterval(() => {
    //       setCounterKey(Math.random());
    //       setActiveIdx(0);

    //     }, posts.length * 10001)
    // }, [])
    const myPortableTextComponents = {
      block: {
        normal: ({children}) => <p className="description">{children}</p>,
        h2: ({children}) => <h2 className="heading2">{children}</h2>,
      }
    }
        
    const post1 = posts[activeIdx];
      // if(activeIdx===posts.length){
      //   setActiveIdx=0;
      // }

    // const handleChange = ()=> {
    //     setClassName("fade-in-2");
    // };
    return (
        <div className="testimonials">  
            <Container> 
            <Row style={{textAlign:"center"}}>
                {posts.map((post, index) =>
              <Col style={{paddingRight:"unset", paddingLeft:"unset"}}>
                <div className="tst-container" onClick={()=>{ setActiveIdx(index)
                  }}>
                
                  <CountdownCircleTimer
                      key={`${index}`}
                      isPlaying={index===activeIdx}
                      duration={15}
                      size={74}
                      strokeWidth={5}
                      // strokeLinecap={['square']}
                      // trailColor={['#4dc3ff']}
                      colors={[["#ffffff"]]}
                      onComplete={()=> {
                        setClassName("fade-in-"+activeIdx);
                        setActiveIdx(index < (posts.length-1) ? activeIdx+1 : 0)
                        return [true, 0];
                        
                      }}
                      >
                        {/* {renderTime} */}
                        <div className="timer">
                          <div className="text">
                            {post?.image && <img style={{width:"68px", height:"68px"}} src={post.image.asset.url} />}
                          </div>
                        </div>
                  </CountdownCircleTimer>
                    <div className="tst-text">
                    {/* <p>{posts.length}</p> 
                <p>{activeIdx}</p> 
                    <p>{index}</p> */}
                    <p className="username">{post.name}</p>
                    <p className="position">{post.position}, {post.company}</p>
                    </div>
                  </div>
                </Col>
                )}
            </Row>
            <Row>
              <div className={`description-container ${className}`} key={activeIdx}>
                <PortableText value={posts[activeIdx]._rawDescription} components={myPortableTextComponents} />
                <p className="position" >- {posts[activeIdx].name}, {posts[activeIdx].company}</p>
              </div>
            </Row>
            </Container>
        </div>
    )
}

export const query = graphql`
  {
    allSanityTestimonials(sort: {fields: _createdAt}) {
      nodes {
        position
        name
        company
        image {
          asset {
            url
          }
        }
        _rawDescription
      }
    }
  }
`



