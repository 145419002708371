import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Row, Col, Container, Jumbotron, Figure} from 'react-bootstrap';
import {PortableText} from '@portabletext/react'

import '../assets/style.css';

export default function() {
    const data = useStaticQuery(query);
    const {allSanityServices:{
        nodes:services
    }} = data

    return (
        <div className="service">  
            <Container> 
                <Row>
                    <div className="our-services">
                        <h2>Our services</h2> 
                        <span>Our team is dedicated to create and improve the user experience, interfaces and applications to complement our clients business needs.</span>
                    </div>
                </Row>
            </Container>
                <div className="services-section">
                    {services.map((services) => {
                        if (services?.hide) return<></>;
                        return <div className="service-col">
                            <a href={`/services/${services.slug}/`}> 
                                <Jumbotron className={`service-box ${services.slug}`}  key={services.id} >
                                    <div className="service-content">
                                        <div className="service-img">
                                            <div className="service-img-ctn"><img width="100%" height="auto" src={services?.image?.asset?.url} alt="Logo service" /></div>
                                            <h3 className="service-title">{services.title}</h3>
                                        </div>
                                        <div className="service-text">
                                            {/* <img width="100%" height="auto" src={services.image.fluid.src} alt="Logo service" /> */}
                                            <h3 className="service-title">{services.title}</h3>
                                            <span className="service-info"><PortableText value={services._rawInfo}/></span>
                                        </div>
                                    </div>
                                </Jumbotron> 
                            </a> 
                        </div>
                    })}
                </div>
        </div>
    )
}
export const query = graphql`
{
    allSanityServices(sort: {fields: _createdAt}) {
        nodes {
          id
          title
          slug
          _rawInfo
          image {
            asset {
              url
            }
          }
          hide
        }
    }
}
`


