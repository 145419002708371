import React from 'react'
import { graphql, useStaticQuery } from "gatsby"
import '../assets/style.css';
import { Row, Container, Col} from 'react-bootstrap';

export default function() {
    const data = useStaticQuery(query);
    const {allSanityClients:{
        nodes:clients
    }} = data


    return (
        <div className="clients">  
            <Container> 
            <Row> 
                <div className="client-title"> 
                    <p>Sogody engineers digital experiences to help clients <strong><i>convert</i></strong> without pitching. We work with international brands, including:</p>
                </div>
            </Row>
            <div className="client-logos"> 
                <Row>
                    {clients.map((client)=>{
                        return <>
                        <Col md={3} sm={3} lg="2" xs={4} style={{ marginBottom:"30px",  display: "flex", justifyContent: "center", alignItems: "center"}} className={client?.hide ? "hide-mobile": ""}>
                            <img className="client-img-s"  style={{ width: "100%", height: "100%", maxHeight: "65px"}} src={client.clientLogo?.asset.url} alt="Logo" />
                        </Col>
                        {/* <Image className="client-logo"  fluid={clients.clientLogo.fluid} alt="Client img"/> */}
                        </> 
                    })}
                </Row> 
            </div>
            </Container></div>
    )
}

export const query = graphql`
{
    allSanityClients(sort: {fields: _updatedAt, order: DESC}) {
        nodes {
          clientLogo{
            asset{
                url
            }
          }
          hide
        }
      }
}
`

