import React from 'react'
import { graphql, useStaticQuery } from "gatsby"
import '../assets/style.css';
import { Row, Container, Col } from 'react-bootstrap';
import { Link } from 'gatsby';

export default function () {
    const data = useStaticQuery(query);
    if (!data?.sanityPartnersLogo) return <></>;
    
    const { sanityPartnersLogo: {
        logos, title
    } } = data

    return (
        <div className="clients">
            <Container>
                <Row>
                    <div className="client-title">
                        <p>{title}</p>
                    </div>
                </Row>
                <div className="client-logos">
                    <Row>
                        {logos?.map((item) => (
                            <Col md={3} sm={3} lg="2" xs={4} style={{ marginBottom: "30px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Link to={item?.link} target="_BLANK" rel="noreferrer" style={{ width: "70%" }}>
                                    <img className="client-img-s" style={{ width: "100%", height: "100%", maxHeight: "65px" }} src={item.icon?.asset.url} alt="Logo" />
                                </Link>
                            </Col>
                        ))}
                    </Row>
                </div>
            </Container ></div >
    )
}

export const query = graphql`
{
    sanityPartnersLogo {
      title
      logos {
        icon {
          asset {
            url
          }
        }
        link
      }
    }
  }
`

